<style lang="scss"></style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(addCategory)">
        <section class="mb-2">
          <div>
            <label>Name</label>
            <validation-provider
              v-slot="validationContext"
              name="Name"
              :rules="{ required: true, min: 1 }"
            >
              <b-form-input
                v-model="category.name" class="mb-1" placeholder="Enter name"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div>
            <label>Max Items</label>
            <validation-provider
              v-slot="validationContext"
              name="Max Items"
              :rules="{ min: -1 }"
            >
              <b-form-input
                v-model="category.max_items"
                type="number"
                placeholder="Leave blank for no limit"
                :number="true"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div class="mt-1">
            <label>Exclude from location max items</label>
            <b-form-select v-model="category.exclude_from_location_max_items">
              <b-form-select-option selected :value="false">No</b-form-select-option>
              <b-form-select-option :value="true">Yes</b-form-select-option>
            </b-form-select>
          </div>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button variant="primary" type="submit" :disabled="invalid">Add new category</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import CategoryService from '@/services/PossessionCategoriesService';

export default {
  components: {},
  props: {
    parentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      groups: [],
      showPassword: false,
      category: {
        name: '',
        parent_id: this.parentId,
        max_items: "",
        exclude_from_location_max_items: false,
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    addCategory() {
      this.category.name = this.category.name.trim();

      // If max_items is empty, set it to null
      if (this.category.max_items === "") {
        this.category.max_items = -1;
      }

      CategoryService.createCategory(this.category).then(() => {
        this.$toast.success(`Added category ${this.category.name} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.$emit('refresh', true);
      }).catch(err => {
        const res = err.response;
        let errorText = 'Could not add category, please refresh and try again';

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
  },
};
</script>
