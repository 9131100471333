<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="users-page">

    <b-card class="" title="Categories">
      <b-card-text>This page shows all possession categories in your enterprise. You can manage and create categories directly from this page.</b-card-text>
    </b-card>

    <b-card no-body class="mb-0">

      <div class="m-2">
        <b-row>
          <b-col v-if="$can('Create', 'Owner')" cols="12" md="3" lg="2" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <b-button @click="$bvModal.show('modal-add-category')" variant="primary">Create Category</b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="9" lg="10">
            <div class="d-flex align-items-center justify-content-end">

              <b-input-group>
                <b-form-input
                  @keydown.enter="searchCategories()"
                  v-model="searchVal"
                  class="d-inline-block"
                  placeholder="Search for a category..."
                />
                <b-input-group-append>
                  <b-button @click="searchCategories()">Search</b-button>
                </b-input-group-append>
              </b-input-group>

              <section class="col-2 p-0 ml-1 d-flex justify-content-end align-items-center">
                <b-button @click="clearFilters()">Clear filters</b-button>
              </section>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="data-table"
        hover
        :items="categories"
        :fields="headers"
        :current-page="currentPage"
        :per-page="0"
        @sort-changed="onSortChanged"
      >
        <template #cell(name)="data">
          <router-link :to="{ name: 'possessions-category', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
            {{data.item.name}}
          </router-link>
        </template>
        <template #cell(max_items)="data">
          <span v-if="data.item.max_items === -1">No limit</span>
          <span v-else>{{data.item.max_items}}</span>
        </template>
        <template #cell(exclude_from_location_max_items)="data">
          <span v-if="data.item.exclude_from_location_max_items">Yes</span>
          <span v-else>No</span>
        </template>
        <template #cell(actions)="data">
          <b-dropdown class="m-0"  variant="link" right toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Owner')"
                                    @click="showCreateModal(data.item, 'modal-update-category')">
              Update category
            </b-dropdown-item-button>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Delete', 'Owner')"
                                    @click="showCreateModal(data.item, 'modal-delete-category')">
              Delete category
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <b-row>
        <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
          <b-pagination @change="getMoreCategories" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
        </b-col>
        <b-col class="p-2 pl-3" v-if="total_count === 0">No Categories found</b-col>
      </b-row>

    </b-card>

    <b-modal id="modal-add-category" title="Add new category" hide-footer>
      <add-category
        @refresh="refreshCategories"
        @close="closeModals()"
      ></add-category>
    </b-modal>

    <b-modal id="modal-update-category" title="Update category" hide-footer>
      <update-category
        v-if="targetCategory"
        :target-category="targetCategory"
        @refresh="refreshCategories()"
        @close="closeModals()"
      ></update-category>
    </b-modal>

    <b-modal id="modal-delete-category" title="Delete category" hide-footer>
      <delete-modal
        @close="closeModals()" @delete="deleteCategory(targetCategory)"
        title="Are you sure you wish to delete this category"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
  import 'vue-search-select/dist/VueSearchSelect.css';
  import DeleteModal from "@/components/modals/DeleteModal.vue";
  import CategoriesService from "@/services/PossessionCategoriesService";
  import AddCategory from "@/views/possessions/modals/AddCategory.vue";
  import UpdateCategory from "@/views/owners/sections/UpdateCategory.vue";

  export default {
    name: 'PossessionCategories',
    components: {
      UpdateCategory,
      AddCategory,
      DeleteModal,
    },
    props: {},
    data() {
      return {
        targetCategory: null,
        headers: [
          {
            key: 'name',
            label: 'Name',
            sortable: true,
          },
          {
            key: 'max_items',
            label: 'Max Items',
          },
          {
            key: 'exclude_from_location_max_items',
            label: 'Excluded from location max items'
          },
          {
            key: 'actions',
            label: '',
            class: 'text-right',
          },
        ],
        categories: [],
        currentPage: 1,
        searchVal: '',
        filters: {
          page: 0,
          page_size: 25,
          search: '',
          parent_id: "0",
        },
        total_count: 0,
      };
    },
    watch: {
      filters: {
        deep: true,
        handler: function(newVal) {
          let queryString = `?page=${newVal.page+1}`;
          if(newVal.search !== '') {
            queryString += `&search=${newVal.search}`
          }
          history.replaceState({}, null, `${this.$route.path}${queryString}`)
        }
      }
    },
    mounted() {
      // Set filters from query params
      if(this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page, 10)
        this.filters.page = this.currentPage - 1
      }
      if(this.$route.query.search) {
        this.filters.search = this.$route.query.search
      }
      this.getCategories();
    },
    methods: {
      onSortChanged({sortBy, sortDesc}) {
        this.filters.order_by = sortDesc ? 'desc' : 'asc'
        this.filters.order = sortBy
        this.searchCategories()
      },
      clearFilters() {
        this.currentPage = 1
        this.searchVal = ''
        this.filters = {
          page: 0,
          page_size: 25,
          search: '',
          parent_id: 0,
        }
        this.searchCategories()
      },
      refreshCategories() {
        this.closeModals();
        this.getCategories();
      },
      closeModals() {
        this.$bvModal.hide('modal-add-category');
        this.$bvModal.hide('modal-delete-category');
        this.$bvModal.hide('modal-update-category');
      },
      searchCategories() {
        this.filters.search = this.searchVal
        this.currentPage = 1;
        this.filters.page = 0;
        this.getCategories();
      },
      getMoreCategories(val) {
        this.filters.page = val - 1;
        this.getCategories();
      },
      getCategories() {
        CategoriesService.listCategories(this.filters).then(res => {
          this.categories = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get categories, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      showCreateModal(category, modalName) {
        this.targetCategory = category;
        this.$bvModal.show(modalName);
      },
      deleteCategory(category) {
        CategoriesService.deleteCategory(category.id).then(() => {
          this.$toast.success(`Deleted category ${category.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.refreshCategories();
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not delete category, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
    },
  };
</script>
