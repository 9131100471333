import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/possession-categories';

export default {
  listCategories(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  get(id) {
    return axios.get(`${baseUrl}/${id}`);
  },
  createCategory(category) {
    return axios.post(`${baseUrl}`, category);
  },
  deleteCategory(id) {
    return axios.delete(`${baseUrl}/${id}`);
  },
  updateCategory(id, userDto) {
    return axios.put(`${baseUrl}/${id}`, userDto);
  },
};
